import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { getClasses } from "@washingtonpost/front-end-utils";
import get from "lodash.get";
import set from "lodash.set";
import { useAssemblerContext } from "~/shared-components/AssemblerContext";
import { useBreakpoints } from "~/shared-components/BreakpointContext";

import {
  BACKUP_PROMO_IMAGE,
  getAspectRatio,
  isGif,
  isMp4
} from "~/shared-components/video/video-helpers";
import getResizedUrl from "~/components/core/elements/image/resize-url";

import { getAspectRatioClasses } from "../_utilities/helpers";

import Warning from "~/shared-components/Warning";
import Caption from "./Caption";

import Video from "~/shared-components/video/default.jsx";
import { getInlineVideoOverlay, getPromoImage } from "./Video.helpers";
import getImageToolbarItems from "./Image.toolbar";

const VideoFlex = (props) => {
  const {
    videoData,
    caption,
    ads,
    allowAnimation,
    artOverlayShow,
    artOverlayText,
    includeDurationOrLive,
    muted,
    disableLazyLoading,
    isAdmin
  } = props;
  let { autoplay } = props;

  const breakpoints = useBreakpoints();
  const { EditableArt } = useAssemblerContext();

  if (!videoData) return null;

  const tags = get(videoData, "taxonomy.tags", []).map((tag) => tag.text);
  const isLoopingRaw = !!tags.filter((_) => /^looping-/.test(_)).length;
  let isLooping = !!isLoopingRaw;

  // START: Set autoplay, isLooping
  if (isLooping) {
    // NOTE: If looping, force autoplay to true on published page
    // In admin, looping videos obey the autoplay flag
    if (!isAdmin) autoplay = true;
    // NOTE: If isLooping, powa forces autoplay, which is not desired in the admin,
    // so set isLooping to false if autoplay is not on in the admin
    if (isAdmin && !autoplay) isLooping = false;
  } else {
    // NOTE: If not looping, force autoplay to false in admin
    autoplay = isAdmin ? false : autoplay;
  }
  // END: Set autoplay

  const usePromoVideo =
    allowAnimation && isMp4(get(videoData, "promo_items.gif.url"));
  set(videoData, "additional_properties.gifAsThumbnail", usePromoVideo);

  const promoImage = getPromoImage({ overrides: props, videoData });

  const warning =
    isAdmin && promoImage === BACKUP_PROMO_IMAGE
      ? "Enter a Cover Art URL, otherwise no art (or the wrong art) will appear on the Apps."
      : undefined;

  const contentProps = {
    unresizedAlternateArt: promoImage,
    alternateArt: isGif(promoImage)
      ? promoImage
      : getResizedUrl(promoImage, { w: 800 }),
    disableLazyLoading
  };

  const videoType = get(videoData, "video_type");
  const isLive = videoType === "live" || false;
  const overlay = getInlineVideoOverlay(
    videoData,
    isLive,
    artOverlayShow,
    artOverlayText,
    includeDurationOrLive
  );

  const aspectRatio = getAspectRatio(videoData);

  const videoConfig = {
    ads,
    aspectRatio: 1 / aspectRatio,
    autoplay,
    autoinit: true,
    className: getClasses("flex-feature", {
      "mb-xxs": caption,
      "mb-xs": !caption,
      [getAspectRatioClasses(aspectRatio)]: aspectRatio
    }),
    customBtnText: isLive ? overlay.secondary_text : overlay.text,
    includeDurationOrLive,
    isAdmin,
    isLive,
    isLooping,
    isLoopingRaw,
    loadByFeature: true,
    muted,
    playthrough: false,
    showBlurb: false,
    showPreview:
      !isAdmin && isLive && autoplay === true && muted === true && !ads,
    showPreview2:
      !isAdmin && isLive && autoplay === true && muted === true && ads === true,
    showTitle: false
  };

  const toolbarItems = getImageToolbarItems({
    breakpoints,
    ...props
  });

  // When the video returns a status "ended" we allow hp refresh
  // uncomment and add functionality once allowRefresh() is available
  // useEffect(() => {
  //   const ONE_SECOND = 1000;
  //   const uuid = contentProps.uuid;
  //   if (videoConfig.isLive) {
  //     const checkForEnd = setInterval(async () => {
  //       const videoInfo = await fetch(
  //         `http://video-api.washpost.arcpublishing.com/api/v1/ansvideos/findByUuid?uuid=${uuid}&noRedirect=true`
  //       );
  //       const data = await videoInfo.json();
  //       if (data[0].status === "ended") // Do whatever is required to refresh
  //     }, 120 * ONE_SECOND);
  //     return () => clearInterval(checkForEnd);
  //   }
  //   return () => {};
  // });

  return (
    <Fragment>
      {warning && <Warning message={warning} level="warning" />}
      <EditableArt menuItems={toolbarItems}>
        <Video
          key={`${videoConfig.customBtnText}-${contentProps.alternateArt}-${videoData._id}-${autoplay}-${allowAnimation}`}
          videoConfig={videoConfig}
          {...contentProps}
          videoData={videoData}
        />
      </EditableArt>
      {caption && <Caption {...caption} />}
    </Fragment>
  );
};

VideoFlex.propTypes = {
  ads: PropTypes.bool,
  allowAnimation: PropTypes.bool,
  alternateArt: PropTypes.string,
  artOverlayText: PropTypes.string,
  artOverlayShow: PropTypes.bool,
  autoplay: PropTypes.bool,
  isAdmin: PropTypes.bool,
  includeDurationOrLive: PropTypes.bool,
  muted: PropTypes.bool,
  url: PropTypes.string,
  videoData: PropTypes.object,
  caption: PropTypes.object,
  disableLazyLoading: PropTypes.boolean
};

export default VideoFlex;
